//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toDisplayDateWithoutTime, addHours } from './../../utils/dateTimeUtils.js';
import calendarInfo from './../../calendar/calendar-info.json';
import BookingGroup from './bookingGroup.js';
import BookingListFilter from './bookingListFilter.js';
import ProcessingCircle from './../../utils/processingCircle.js';

//CSS
import './bookingList.css';

function BookingList({loginUser, logout}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [bookingList, setBookingListList] = useState(null);

    const getUrlParams = () => {
        const params = new URLSearchParams(window.location.search);
        const entries = params.entries();
        const result = {};
        for (const [key, value] of entries) {
            result[key] = value;
        }
        return result;
    };

    const defaultActiveFilter = { 
        time: 'future', 
        filterEmail: null, 
        withBreaks: false, 
        withHolidays: false, 
        withVacations: false, 
        withOtherAbsences: false,
        cancellation: 'all'
    };

    const [activeFilter, setActiveFilter] = useState(() => {
        const urlParams = getUrlParams();
        return {
            time: urlParams.time || defaultActiveFilter.time,
            filterEmail: urlParams.filterEmail || defaultActiveFilter.filterEmail,
            withBreaks: urlParams.withBreaks ? urlParams.withBreaks === 'true' : defaultActiveFilter.withBreaks,
            withHolidays: urlParams.withHolidays ? urlParams.withHolidays === 'true' : defaultActiveFilter.withHolidays,
            withVacations: urlParams.withVacations ? urlParams.withVacations === 'true' : defaultActiveFilter.withVacations,
            withOtherAbsences: urlParams.withOtherAbsences ? urlParams.withOtherAbsences === 'true' : defaultActiveFilter.withOtherAbsences,
            cancellation: urlParams.cancellation || defaultActiveFilter.cancellation
        };
    });

    const setActiveFilterProperty = (property, value) => {
        // console.log(value);
        setActiveFilter(prevState => ({
            ...prevState,
            [property]: value,
        }));
    };

    const actualTime = new Date();
    const actualDayStart = new Date(actualTime.getFullYear(), actualTime.getMonth(), actualTime.getDate());
    const actualDayEnd = new Date(actualDayStart);
    actualDayEnd.setDate(actualDayEnd.getDate() + 1);
    const groupBookings = (bookingList) => {
    
        // Group the bookings and initialize groupsArray
        const groupedBookings = bookingList.reduce((headerToBookings, booking) => {
            const bookingDateTimeStart = new Date(booking["date"]);
            const bookingDateTimeEnd = addHours(bookingDateTimeStart, booking["hour_to"] - booking["hour_from"]);
            const beforTodayStart = bookingDateTimeEnd <= actualDayStart;
            const afterTodayEnd = actualDayEnd <= bookingDateTimeStart;
            const monthYear = t(calendarInfo.months[bookingDateTimeStart.getMonth()]) + " " + bookingDateTimeStart.getFullYear();
            let header;
    
            if (beforTodayStart) {
                header = t("booking-past") + " - " + monthYear;
            } else if (afterTodayEnd) {
                header = t("booking-upcoming") + " - " + monthYear;
            } else {
                header = t("booking-today") + " - " + toDisplayDateWithoutTime(bookingDateTimeStart);
            }
    
            if (!headerToBookings[header]) {
                headerToBookings[header] = [];
            }
    
            headerToBookings[header].push(booking);
            return headerToBookings;
        }, {});
    
        // Convert the grouped bookings object to an array of groups
        const groupsArray = Object.keys(groupedBookings).map((header) => ({
            header,
            bookings: groupedBookings[header],
            index: 0, // Default index, will be updated next
        }));
    
        // Find the index of the group with today's bookings or the closest future booking
        const todayGroupIndex = groupsArray.findIndex(group => group.header.includes(t("booking-today")));
        const closestFutureIndex = todayGroupIndex !== -1 ? todayGroupIndex : groupsArray.findIndex(group => {
            const firstBookingTime = new Date(group.bookings[0]["date"]).getTime();
            return firstBookingTime >= actualTime.getTime();
        });
    
        // Assign indices based on the closest future booking or today's bookings
        groupsArray.forEach((group, idx) => {
            group.index = idx - (closestFutureIndex === -1 ? 0 : closestFutureIndex);
        });
    
        //Order bookings within groups
        if (activeFilter.time === 'past') {
            groupsArray.forEach((group, idx) => {
                group.bookings = group.bookings.sort((a, b) => {
                    return new Date(a.date) < new Date(b.date)
                });
            });
        }

        //return groupsArray.sort((a, b) => activeFilter.time !== 'past' ? a.index - b.index :  b.index - a.index);
        return groupsArray.sort((a, b) => activeFilter.time !== 'past' ? a.index - b.index :  b.index - a.index);
    };

    const loadBookingList = async () => {
        try {
            const bookingInfoUrl = `/api/therapist/booking-list.php`;
            const response = await axios.get(bookingInfoUrl);
            setBookingListList(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    //Load booking list at page loading
    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;

        loadBookingList();
    }, []);

    //Reload booking list in an interval
    useEffect(() => {
        const intervalId = setInterval(() => {
            loadBookingList();
        }, 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);
    
    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (bookingList == null) {
        return <div>
            <h2>{t('data-loading')}</h2>
            <ProcessingCircle></ProcessingCircle>
        </div>
    }

    if (bookingList.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    const filteredBookingList = bookingList.reduce((acc, booking) => {
        const bookingDate = new Date(booking['date']);
        if (
            (
                activeFilter.time === 'all' ||
                activeFilter.time === 'future' && bookingDate > actualDayStart ||
                activeFilter.time === 'past' && bookingDate < actualDayEnd
            ) && (
                activeFilter.filterEmail === '' ||
                activeFilter.filterEmail === null ||
                booking.email === activeFilter.filterEmail
            ) && (
                activeFilter.withBreaks || booking.booking_type !== 6
            ) && (
                activeFilter.withHolidays || booking.booking_type !== 2
            ) && (
                activeFilter.withVacations || booking.booking_type !== 4
            ) && (
                activeFilter.withOtherAbsences || booking.booking_type === 1 || 
                booking.booking_type === 2 || booking.booking_type === 4 || 
                booking.booking_type === 6
            ) && (
                activeFilter.cancellation === 'all' ||
                activeFilter.cancellation === 'scheduled' && !booking['cancel_date'] ||
                activeFilter.cancellation === 'cancellation' && booking['cancel_date']
            )
        ) {
            acc.push(booking);
        }
        return acc;
    }, [])

    return (
        <div className='bookingList'>
            <h3>{t('cl-filter-header')} ({filteredBookingList.length})</h3>
            <BookingListFilter 
                bookingList={bookingList} 
                activeFilter={activeFilter} 
                setActiveFilterProperty={setActiveFilterProperty}>
            </BookingListFilter>
            {groupBookings(filteredBookingList).map(group => {
                return (
                    <React.Fragment key={"booking-group-fragment-" + group.index}>
                        <h3>{group.header}</h3>
                        <BookingGroup
                            key={"booking-group-" + group.index}
                            loginUser={loginUser}
                            logout={logout}
                            group={group}
                            loadBookingList={loadBookingList} >
                        </BookingGroup>
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default BookingList;
