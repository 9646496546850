// Libraries
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getUserDisplay } from '../../utils/businessUtils.js';
import LangLink from '../../translations/LangLink.js'
import VoucherPaymentList from './voucherPaymentList.js'
import VoucherEdit from './voucherEdit.js'
import PostLink from '../../utils/postLink.js';

function SelectedVoucher({ loginUser, logout, voucher, loadVoucherList }) {
    const { t } = useTranslation();
    const [editVoucher, setEditVoucher] = useState(false);

    const archiveAction = voucher['archive_date'] ? 'restore' : 'push'
    const voucherArchive = async () => {
        try {
            const deliverUrl = '/api/voucher.php?action=archive&archive-action=' + archiveAction + '&voucherId=' + voucher.id;
            const response = await axios.get(deliverUrl);
            if (response.data.Status === "success") {
                loadVoucherList();
            }
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    return (
        <div>
            <h3>{t('voucher-selected') + " V" + voucher.id.toString().padStart(6, '0')}</h3>
            <div className='selected-voucher-box'>
                <p>{t('voucher-code')}: {voucher.code}</p>
                <p>{t('voucher-buyer')}: {getUserDisplay(voucher)}</p>
                {editVoucher ? 
                    <VoucherEdit
                        logout={logout}
                        voucher={voucher}
                        loadVoucherList={loadVoucherList} >
                    </VoucherEdit> : 
                    <button onClick={() => setEditVoucher(true)}>
                        {t('edit')}
                    </button>}
                <button onClick={() => voucherArchive()}>
                    {t('voucher-archive-' + archiveAction)}
                </button>
                {voucher['payment'] == 2 ? (
                    <div>
                        <h4>{t('invoice')}</h4>
                        <p>
                            <LangLink className='link-button' target='_blank' to={'/api/pdf/voucher-invoice.php?code=' + voucher.code}>
                                {t('open-invoice')}
                            </LangLink>
                            <PostLink className='link-button' 
                                to={'/api/voucher.php?action=send-invoice&voucherId=' + voucher.id}
                                successContent={t('invoice-sent')}
                                >
                                {t('send-invoice-email')}
                            </PostLink>
                            {" " + t('voucher-send3')}{" " + voucher['payer_email']}
                        </p>
                    </div>
                ) : null}
                <h4>{t('voucher-open-and-send')}</h4>
                <p>
                    {t('voucher-open') + ": "}
                    <LangLink className={'link-button'} target='_blank' to={'/api/pdf/voucher.php?language_code=de&code=' + voucher.code}>
                        {t('language-de')}
                    </LangLink> <LangLink className={'link-button'} target='_blank' to={'/api/pdf/voucher.php?language_code=en&code=' + voucher.code}>
                        {t('language-en')}
                    </LangLink> <LangLink className={'link-button'} target='_blank' to={'/api/pdf/voucher.php?language_code=ru&code=' + voucher.code}>
                        {t('language-ru')}
                    </LangLink>
                </p>
                <p>
                    {t('voucher-send1')} CHF{voucher['voucherSaldo'] + " "}
                    <PostLink className='link-button' 
                        to={'/api/voucher.php?action=delivery&code=' + voucher.code}
                        successContent={t('voucher-sent')}
                        >
                        {t('voucher-send2')}
                    </PostLink>
                    {" " + t('voucher-send3')}
                    {" " + voucher['payer_email']}
                </p>

                <h4>{t('transactions')}</h4>
                <VoucherPaymentList 
                    loginUser={loginUser} 
                    logout={logout}
                    voucher={voucher}
                    loadVoucherList={loadVoucherList}>
                </VoucherPaymentList>
            </div>
        </div>
    );
}

export default SelectedVoucher;
