//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import SpellCheckTextareaAutosize from '../utils/SpellCheckTextareaAutosize.js';
import paymentTypes from './bookingList/paymentTypes.json'
import { roundHalfUp } from '../utils/mathUtil.js';
import LoadTwoRowCustomerFilter from './filters/loadTwoRowCustomerFilter.js'
import { getAquiredCustomerDisplay } from '../utils/businessUtils.js'
import VoucherDropDown from './voucher/voucherDropDown.js'
import { toIsoDateWithoutTime } from './../utils/dateTimeUtils.js';
import ProcessingCircle from './../utils/processingCircle.js';
// import DatePicker from './../datePicker/datePicker.js'

//CSS
import './paymentList.css';

function PaymentList({loginUser, logout, targetType, targetId, targetPrice, previousPayments, updateAfterChanges, acquireCustomerToggle, suppressReload}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);

    const [percentDiscount, setPercentDiscount] = useState(0);
    const [paymentListData, setPaymentListData] = useState(null);
    const [showAcquiredCustomer, setShowAcquiredCustomer] = useState(false);
    const [paymentIdToDate, setPaymentIdToDate] = useState(null);
    const [formData, setFormData] = useState({
        targetId,
        amount: 0,
        typeId: 1,
        transactionText: '',
        aquiredCustomerEmail: '',
        voucherId: 0
    });

    const loadPaymentDialogueByBooking = async (id) => {
        try {
            const bookingInfoUrl = '/api/therapist/payment.php?action=list&targetType=' + targetType + '&targetId=' + id;
            const response = await axios.get(bookingInfoUrl);
            setPaymentListData(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    const paymentAdd = async (e) => {
        e.preventDefault();
        axios.post('/api/therapist/payment.php?action=add&targetType=' + targetType, formData)
        .then(() => {
            updateAfterChanges();
            loadPaymentDialogueByBooking(targetId);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const paymentRemove = async (e, paymentId) => {
        e.preventDefault();
        axios.post('/api/therapist/payment.php?action=remove&targetType=' + targetType, {paymentId})
        .then(() => {
            updateAfterChanges();
            loadPaymentDialogueByBooking(targetId);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const handlePercentDiscount = (e) => {
        e.target.focus(); //required together with onBlur to take effect
        setPercentDiscount(e.target.value);
    };
    const getPercentFactor = () => {
        return (100 - percentDiscount) / 100;
    };
    
    useEffect(() => {
        //Ensure to load only once
        if (isFetchingRef.current) {
            const newAmount = roundHalfUp(targetPrice * getPercentFactor(percentDiscount), 2) - previousPayments;
            handlePropertyValueChange("amount", newAmount);
            if (suppressReload) {
                return;
            }
        }  else {
            isFetchingRef.current = true;
        }

        if (targetId) {
            loadPaymentDialogueByBooking(targetId);
            const newAmount = roundHalfUp(targetPrice * getPercentFactor(percentDiscount), 2) - previousPayments;
            handlePropertyValueChange("amount", newAmount);
        }
    }, [targetId, previousPayments, percentDiscount]);

    const handlePropertyValueChange = (property, value) => {
        // console.log(property + " => " + value)
        setFormData({ ...formData, [property]: value });
    };

    const handleChange = (e) => {
        // console.log(e);
        e.target.focus(); //required together with onBlur to take effect
        const { name, value, nodeName } = e.target;
        if (nodeName === "SELECT") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedOptionId = selectedOption.getAttribute("value");
            handlePropertyValueChange(name, selectedOptionId);
        } else {
            handlePropertyValueChange(name, value);
        }
    };

    const setAquiredCustomerEmail = (email) => {
        setFormData({
            targetId,
            amount: -10,
            typeId: 2,
            transactionText: formData.transactionText,
            aquiredCustomerEmail: email
        });
    }

    const setPaymentDate = (paymentId, date) => {
        setPaymentIdToDate({paymentId, date})
    }

    const sendPaymentDate = async (e) => {
        e.preventDefault();
        if (!paymentIdToDate) return;
        axios.post('/api/therapist/payment.php?action=date-update&targetType=' + targetType, paymentIdToDate)
        .then(() => {
            updateAfterChanges();
            loadPaymentDialogueByBooking(targetId);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    
    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (paymentListData == null) {
        return <div>
            <h2>{t('data-loading')}</h2>
            <ProcessingCircle></ProcessingCircle>
        </div>
    }
    
    const showVoucherDropDown = formData.typeId == 4;
    const showVoucherCodeColumn = formData.typeId === 4 || paymentListData.some(payment => payment.type_id === 4);
    const showPaymentDate = paymentListData.some(payment => payment.type_id == 7);
    const anyAquisition = paymentListData.some(payment => payment.au_firstname || payment.au_lastname);
    const paymentListRows = paymentListData ? paymentListData.map((payment) => {
        const paymentType = paymentTypes.find(item => item.id === payment["type_id"]) ?? "unknown";
        return (
            <tr key={"payment-id-" + payment.id}>
                <td>
                    <button onClick={(e) => paymentRemove(e, payment["id"])}>-</button>
                </td>
                <td>{payment["amount_chf"]}</td>
                <td>{t(paymentType["label"])}</td>
                {showVoucherCodeColumn ? <td>{payment["voucherCode"]}</td> : null}
                {showPaymentDate ? <td>
                    {payment.type_id == 7 ? (
                        <input  id='date' name='date' type='date'
                            defaultValue={toIsoDateWithoutTime(new Date(payment.date))}
                            onChange={(e) => setPaymentDate(payment.id, e.target.value)}
                            onBlur={sendPaymentDate}
                        ></input>
                    ) : null}
                </td> : null }
                <td>{payment["transaction_text"]}</td>
                {anyAquisition ? <td>{getAquiredCustomerDisplay(payment)}</td> : null }
            </tr>
        )}
    ) : null;

    const paymentTypesOptions = paymentTypes.map((pType) => {
        if (!pType.activeFor.includes(targetType)) return null;
        return ( <option key={"payment-" + pType.id} id={pType.id} value={pType.id}>{t(pType.label)}</option>)
    });

    const paymentList = (
        <form className='payment-table' onSubmit={paymentAdd}>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        {/* <th>{t("edit-column")}</th> */}
                        <th>{t("payment-amount")}</th>
                        <th>{t("type")}</th>
                        {showVoucherCodeColumn ? <th>{t("voucher-code")}</th> : null }
                        {showPaymentDate ? <th>{t("date")}</th> : null }
                        <th>{t("payment-transaction-text")}</th>
                        {anyAquisition ? <th>{t("acquired-customer")}</th> : null }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {formData.typeId != 4 || formData.voucherId > 0 ? (
                                <button type="submit">+</button>
                            ): null}
                        </td>
                        <td className='payment-percent'>
                            <input id="percent" name="percent" type="number" value={percentDiscount} onChange={handlePercentDiscount}></input>%
                            <br></br>
                            <input id="amount" name="amount" type="number" value={formData.amount} onChange={handleChange}></input>
                        </td>
                        <td>
                            <select id="typeId" name="typeId" value={formData.typeId} onChange={handleChange}>
                                {paymentTypesOptions}
                            </select>
                        </td>
                        {showVoucherCodeColumn ? 
                            <td>
                                {
                                    showVoucherDropDown ?
                                    <VoucherDropDown
                                        loginUser={loginUser}
                                        logout={logout}
                                        voucherId={formData.voucherId}
                                        handleChange={handleChange}>
                                    </VoucherDropDown> : null
                                }
                            </td> : null }
                        {showPaymentDate ? <td></td> : null }
                        <td>
                            <SpellCheckTextareaAutosize id="transactionText" name="transactionText" type="text" value={formData.transactionText} onChange={handleChange} />
                        </td>
                        {anyAquisition ? <th></th> : null }
                    </tr>
                    {paymentListRows}
                </tbody>
            </table>
            {acquireCustomerToggle ? (showAcquiredCustomer ? 
                (
                    <LoadTwoRowCustomerFilter
                        loginUser={loginUser}
                        logout={logout}
                        allCustomerFilterAvailable={false}
                        filterEmail={formData.aquiredCustomerEmail}
                        setFilterEmail={(email) => setAquiredCustomerEmail(email)}
                        choicePerRow={4} >
                    </LoadTwoRowCustomerFilter>
                ) : (
                    <button className='cashback-button' onClick={() => setShowAcquiredCustomer(true)}>Cashback...</button>
                )
            ) : null}
        </form>
    );
    
    return <div className='payment-editor-list'>
        {paymentList}
    </div>
}

export default PaymentList;