//Scripts
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { stringFormat } from './../calendar/calendarLogic';
import { toDisplayDateWithoutTime } from './../utils/dateTimeUtils.js';
import BookingActions from './bookingActions.js';
import ProcessingCircle from './../utils/processingCircle.js';

//CSS
import './currentAndPastBookings.css';

function CurrentAndPastBookings({loginUser, logout}) {
    //Load translations
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);

    //Set email from login user
    const email = loginUser['email'];

    const [bookingInfo, setBookingInfo] = useState(null);
    
    const reloadBookingInfo = async () => {
        try {
            const bookingInfoUrl = `/api/booking/booking-Info.php?email=${email}`;
            const response = await axios.get(bookingInfoUrl);
            setBookingInfo(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    };

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;

        reloadBookingInfo();
    }, []);

    const cancelBooking = async (id, revert) => {
        const response = await fetch('/api/booking/cancel.php',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'User-Agent': navigator.userAgent,
            },
            body: JSON.stringify({ id, revert, cancelTypeId: 1 })
        });
        
        const data = await response.json();
        if (data["success"]) {
            reloadBookingInfo();
        } else {
            console.log(data["error"]);
            logout();
        }
    };
    
    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (bookingInfo == null) {
        return <div>
            <h2>{t('data-loading')}</h2>
            <ProcessingCircle></ProcessingCircle>
        </div>
    }

    const excludedProps = ["id", "reactivate_booking", "paymentSum", "cancel_by_customer"];
    const bookingsTables = ["future", "past", "cancelled_customer", "cancelled_therapist"].map((type) => {
        const infoProp = "bookings_" + type;
        if (bookingInfo.hasOwnProperty(infoProp) && bookingInfo[infoProp] != null && bookingInfo[infoProp].length > 0)
        {
            const templete = bookingInfo[infoProp][0];
            const headerRow = Object.keys(templete).map((property) => {
                if (excludedProps.includes(property)) {
                    return null;
                }
                return ( <th key={"booking-head-" + type + "-" + property} className={property}>{t("booking-prop-" + property)}</th> );
            });
            const tableRows = bookingInfo[infoProp].map((booking) => {
                const cells = Object.keys(templete).map((property) => {
                    var value = booking[property];
                    if (excludedProps.includes(property)) {
                        return null;
                    } else if (property === "date") {
                        value = toDisplayDateWithoutTime(new Date(value));
                    } else if (property === "therapy_label") {
                        value = t(value);
                    } else if (property === "arrangement_duration") {
                        value = value + t("time-minutes-short");
                    } else if (property === "arrangement_price") {
                        if (booking["paymentSum"] && booking["paymentSum"] < booking["arrangement_price"]) {
                            value += " " + t("booking-prop-payment-sum") + " ";
                            value += booking["paymentSum"];
                        }
                    }
                    
                    return ( <td key={type + "-" + booking.id + "-" + property} className={property}>{value}</td> );
                });
                return (
                    <tr key={type + "-booking-row-" + booking.id}>
                        <td><BookingActions type={type} booking={booking} cancelBooking={cancelBooking} /></td>
                        {cells}
                    </tr>
                );
            });
            return (
                <div key={type} className='booking-table-base'>
                    <h2>{stringFormat(t("bookings-" + type + "-header"), bookingInfo[infoProp].length)}</h2>
                    <table className='booking-table'>
                        <thead><tr><th>{t('booking-edit')}</th>{headerRow}</tr></thead>
                        <tbody>{tableRows}</tbody>
                    </table>
                </div>
            );
        } else if (type == "future" || type == "past") {
            return (
                <div key={type}>
                    <h2>{stringFormat(t("bookings-" + type + "-header"), "0")}</h2>
                    <p>{t("bookings-none")}</p>
                </div>
            )
        } else return null;
    });

    return (
        <div>{bookingsTables}</div>
    )
}

export default CurrentAndPastBookings;
