//Scripts
import React from 'react';
import PaymentList from '../paymentList.js';

function VoucherPaymentList({loginUser, logout, voucher, loadVoucherList}) {
    return (
        <PaymentList
            loginUser={loginUser}
            logout={logout}
            targetType='voucher'
            targetId={voucher.id}
            targetPrice={voucher.voucherAmount}
            previousPayments={voucher.voucherSaldo}
            updateAfterChanges={loadVoucherList}
            acquireCustomerToggle={false}
            suppressReload={false}
        >
        </PaymentList>
    );
}

export default VoucherPaymentList;
