//Scripts
import React from 'react';
import PaymentList from '../paymentList.js';
import arrangements from './../../therapy/arrangements.js';

function BookingPaymentList({loginUser, logout, booking, loadBookingList}) {
    const arrangement = arrangements.find((arrangement) => arrangement.id === booking["arrangement_id"]);

    return (
        <PaymentList
            loginUser={loginUser}
            logout={logout}
            targetType='booking'
            targetId={booking.id}
            targetPrice={arrangement.priceInChf}
            previousPayments={booking["sum_chf"]}
            updateAfterChanges={loadBookingList}
            acquireCustomerToggle={true}
            suppressReload={true}
        >
        </PaymentList>
    );
}

export default BookingPaymentList;
