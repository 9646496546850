import React, { useState } from 'react';
import axios from 'axios';
import ProcessingCircle from './processingCircle.js';

const PostLink = ({ to, children, attempts = 1, onSuccess, successContent, ...props }) => {
    const [clickCount, setClickCount] = useState(0);
    const [postCompleted, setPostCompleted] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (clickCount < attempts && !isProcessing) {
            setIsProcessing(true);  // Start processing
            axios.post(to)
                .then(response => {
                    console.log('Request succeeded:', response);
                    
                    if (onSuccess) {
                        onSuccess(response);
                    }

                    setClickCount(prev => prev + 1);
                    if (clickCount + 1 >= attempts) {
                        setPostCompleted(true);
                    }
                })
                .catch(error => {
                    console.error('Request failed:', error);
                })
                .finally(() => {
                    setIsProcessing(false);  // End processing
                });
        }
    };

    if (postCompleted) {
        return (
            <div className="post-done">
                {successContent || children}
            </div>
        );
    }

    return (
        <a href={to} onClick={handleClick} {...props} style={{ display: 'inline-flex', alignItems: 'center' }}>
            {isProcessing ? <ProcessingCircle /> : children}
        </a>
    );
};

export default PostLink;
