// Libraries
import React, { useState } from 'react';
import { t } from "i18next";
import axios from 'axios';
import voucherSujets from './../../vouchers/voucherSujets.json';
import voucherPayments from './../../vouchers/voucherPayments.json';

// CSS
import "./voucherEdit.css";

function VoucherEdit({ logout, voucher, loadVoucherList }) {
    const [currentInputValue, setCurrentInputValue] = useState();

    const updateVoucher = (name, value) => {
        //console.log("name: " + name + " | value: " + value);
        voucher[name] = value;

        const formData = { property: name, voucherId: voucher.id, value };
        axios.post('/api/voucher.php?action=prop-update', formData)
            .then(response => {
                if (response.data.Status === "success") {
                    loadVoucherList();
                }
            })
            .catch(error => {
                console.error('Error:', error);
                logout();
            });
    };

    const onChange = (e) => {
        const { name, value, nodeName } = e.target;
        if (nodeName === "SELECT") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedOptionId = selectedOption.getAttribute("value");
            updateVoucher(name, selectedOptionId);
        } else if (nodeName === "INPUT") {
            setCurrentInputValue({name, value});
        } else {
            console.log("Not supported node name: " + nodeName);
        }
    };

    const onBlur = (e) => {
        let name, value;
        if (!currentInputValue) {
            name = null;
            value = null;
        } else {
            ({ name, value } = currentInputValue);
        }
        
        if (e.target.nodeName === "INPUT") {
            updateVoucher(name, value);
        }
    };

    const formFields = [
        { tag: "input", 
          name: "voucherFrom", 
          type: "text", 
          label: t('voucher-from-name'), 
          value: voucher.voucherFrom || ''
        },
        { tag: "input", 
          name: "voucherTo", 
          type: "text", 
          label: t('voucher-for-name'), 
          value: voucher.voucherTo || '', 
        },
        { tag: "select", 
          name: "sujet", 
          label: t('voucher-subject') + " *", 
          options: voucherSujets, 
          value: voucher.sujet || 0, 
          required: true 
        },
        { tag: "input", 
          name: "voucherAmount", 
          type: "number", 
          label: t('voucher-value-chf'), 
          value: voucher.voucherAmount || '', 
          required: true 
        },
        { tag: "select", 
          name: "payment", 
          label: t('voucher-payment') + " *", 
          options: voucherPayments, 
          value: voucher.payment, 
          required: true 
        },
    ];

    return (
        <div>
            <h4>{t('edit')}</h4>
            <div className="form-container">
                {formFields.map(({ tag, name, type, label, value, options, required }) => (
                    <div className="form-group" key={name}>
                    <label htmlFor={name}>{label}</label>
                    {tag === "input" ? (
                        <input
                        type={type}
                        id={name}
                        name={name}
                        defaultValue={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        required={required}
                        />
                    ) : (
                        <select
                        id={name}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        required={required}
                        >
                        {options.map(option => (
                            <option key={option.id} value={option.id}>
                            {t(option.label)}
                            </option>
                        ))}
                        </select>
                    )}
                    </div>
                ))}
              </div>
        </div>
    )
}

export default VoucherEdit;
