// Libraries
import { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ProcessingCircle from './../../utils/processingCircle.js';

function VoucherDropDown({loginUser, logout, voucherId, handleChange}) {
    const { t } = useTranslation();
    const [vouchers, setVouchers] = useState(null);
    const isFetchingRef = useRef(false);

    const loadVoucherList = async () => {
    const url = `/api/voucher.php?action=list`;
        axios.get(url)
        .then((data) => {
            if (data.data.Status === "success") {
                setVouchers(data.data.vouchers);
            }

            isFetchingRef.current = false;
        })
        .catch(error => {
            console.error('Error:', error);
            logout();
        });
    };

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;
            loadVoucherList();
    }, []);

    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (vouchers == null) {
        return <div>
            <h2>{t('data-loading')}</h2>
            <ProcessingCircle></ProcessingCircle>
        </div>
    }

    if (vouchers.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    const options = vouchers
        .reduce((list, voucher) => {
            if (voucher.voucherSaldo > 0) {
                list.push(voucher);
            }
            return list;
        }, [])
        .map((voucher) => {
            return (
                <option 
                    key={"voucher-code-option-" + voucher.id}
                    value={voucher.id}>
                    {voucher.code + " | CHF" + voucher.voucherSaldo + " | " + t('voucher-buyer') + ": " + voucher.firstname + " " + voucher.lastname}
                </option>
            );
        });
    return (
        <select className="voucher-code-selection" value={voucherId} name="voucherId" onChange={handleChange}>
            <option key={"voucher-code-selection-none"} value="0">{t("please-select")}</option>
            {options}
        </select>
    )
}

export default VoucherDropDown;
