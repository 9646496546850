//Scripts
import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import drawingBackList from './drawingBack/drawing-back.js'

//CSS
import './drawingBoard.css';

const DrawingBoard = ({loginUser, logout}) => {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const canvasRef = useRef(null);
    const [modificationMode, setModificationMode] = useState("draw-stroke");
    const [isSaved, setIsSaved] = useState(true);
    const [background, setBackground] = useState([1, 1, 1]);
    const [strokes, setStrokes] = useState([]);
    const [pencilSize, setPencilSize] = useState(5);
    const [selectedColor, setSelectedColor] = useState('#ff0000');
    const [deleteStrokeCoordinates, setDeleteStrokeCoordinates] = useState(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    var email;
    if (searchParams.get('email')) {
        email = searchParams.get('email');
    }

    const canvasWidth = 1000;
    const canvasHeight = 1000;

    const pencils = [
        { "label": "pencil-small", "pencilSize": 1 },
        { "label": "pencil-medium", "pencilSize": 5 },
        { "label": "pencil-large", "pencilSize": 12 },
    ];

    const colors = [
        { "label": "color-red", "value": "#ff0000" },
        { "label": "color-green", "value": "#00ff00" },
        { "label": "color-blue", "value": "#0000ff" },
        { "label": "color-yellow", "value": "#eeee00" },
        { "label": "color-black", "value": "#000" },
    ];

    const modificationMods = [
        { "label": "draw-stroke" },
        { "label": "delete-stroke" },
        { "label": "clear-drawing" }
    ];

    const loadDrawing = async () => {
        const response = await axios.get('/api/therapist/drawing-load.php?email=' + email);
        if (!response || !response.data) {
            setStrokes([]);
            setBackground([1, 1, 1]);
        }
        else {
            setStrokes(response.data.strokes);
            setBackground([
                response.data['back_slot1_id'],
                response.data['back_slot2_id'],
                response.data['back_slot3_id']
            ]);
        }
    };

    const changeBackgroundClick = (slotId, backOptionId) => {
        const clonedArray = Array.from(background);
        if (clonedArray && clonedArray.length > slotId)
            clonedArray[slotId] = backOptionId;
        saveBackgroundToServer(clonedArray);
        setBackground(clonedArray);
    };

    const doLinesIntersect = (pointA1, pointA2, pointB1, pointB2) => {
        const [x1, y1] = [parseFloat(pointA1.x), parseFloat(pointA1.y)];
        const [x2, y2] = [parseFloat(pointA2.x), parseFloat(pointA2.y)];
        const [x3, y3] = [parseFloat(pointB1.x), parseFloat(pointB1.y)];
        const [x4, y4] = [parseFloat(pointB2.x), parseFloat(pointB2.y)];
        const denominator = ((y4 - y3) * (x2 - x1)) - ((x4 - x3) * (y2 - y1));
        const numerator1 = ((x4 - x3) * (y1 - y3)) - ((y4 - y3) * (x1 - x3));
        const numerator2 = ((x2 - x1) * (y1 - y3)) - ((y2 - y1) * (x1 - x3));
    
        if (denominator === 0) {
            return false; // Lines are parallel
        }
    
        const r = numerator1 / denominator;
        const s = numerator2 / denominator;
        return (r >= 0 && r <= 1) && (s >= 0 && s <= 1);
    };

    // console.log(background);
    const drawBackground = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        for(var posIndex=0; posIndex<3; posIndex++)
        {
            if (!background[posIndex])
                continue;

            const backgroundId = background[posIndex];
            const pos = parseInt(canvasWidth / 3 * posIndex);
            const drawingBack = drawingBackList.find((item) => item.id === backgroundId);
            if (drawingBack && drawingBack["image"]) {
                const image = new Image();
                image.src = drawingBack.image;
                image.onload = () => {
                    context.drawImage(image, pos, 0, parseInt(canvasWidth / 3), canvasHeight);
                };
            }
        }
    };

    const drawLines = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        const drawLine = (start, end, size, color) => {
            context.strokeStyle = color;
            context.lineWidth = size;
            context.beginPath();
            context.moveTo(start.x, start.y);
            context.lineTo(end.x, end.y);
            context.lineWidth = size;
            context.stroke();

            // context.arc(end.x, end.y, size / 2, 0, 2 * Math.PI);
        };

        if (strokes && strokes.length > 0) {
            strokes.forEach(stroke => {
                const { coordinates, size, color } = stroke;
                coordinates.forEach((point, index) => {
                    if (index !== 0) {
                        drawLine(
                            { x: (point.x / canvasWidth) * canvas.width, y: (point.y / canvasHeight) * canvas.height },
                            { x: (coordinates[index - 1].x / canvasWidth) * canvas.width, y: (coordinates[index - 1].y / canvasHeight) * canvas.height },
                            size,
                            color
                        );
                    }
                });
            });
        }
    }

    const deleteAffectedStrokes = (pointA, pointB) => {
        if (!strokes) return;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const updatedStrokes = [];
        let strokesChanged = false;
        for (const stroke of strokes) {
            // console.log(stroke);
            let hasIntersection = false;
            for (let i = 1; i < stroke.coordinates.length; i++) {
                const linePointA = stroke.coordinates[i - 1];
                const linePointB = stroke.coordinates[i];
                if (doLinesIntersect(pointA, pointB, linePointA, linePointB)) {
                    // console.log("Intersection!");
                    hasIntersection = true;
                    strokesChanged = true;
                    break;
                }
            }
    
            if (!hasIntersection) {
                updatedStrokes.push(stroke);
            }
        }
        
        // console.log(strokes.length + " => " + updatedStrokes.length);
        if (strokesChanged) {
            setStrokes(updatedStrokes);
        }
    };

    const startDrawing = e => {
        e.preventDefault();
        const canvas = canvasRef.current;
        if (modificationMode === "clear-drawing") {
            setStrokes(null);
            setBackground([1, 1, 1]);
            axios.post('/api/therapist/drawing-delete.php', { email })
            .then(response => {
                if (response.data) {
                    setStrokes(response.data.strokes);
                    setIsSaved(true);
                    setModificationMode("draw-stroke");
                }
            })
            .catch(error => {
                console.error('Error:', error);
                logout();
            });
        } else if (modificationMode === "delete-stroke") {
            setIsSaved(false);
            setDeleteStrokeCoordinates(getCoordinates(e, canvas));
        } else {
            setIsSaved(false);
            const newLine = { size: pencilSize, color: selectedColor, coordinates: [getCoordinates(e, canvas)] };
            setStrokes(prevLines => prevLines ? [...prevLines, newLine] : [newLine]);
        }
    };

    const draw = e => {
        e.preventDefault();
        if (isSaved || modificationMode === "clear-drawing") return;
        const canvas = canvasRef.current;
        if (modificationMode === "delete-stroke") {
            const newCoordinates = getCoordinates(e, canvas);
            deleteAffectedStrokes(deleteStrokeCoordinates, newCoordinates);
            setDeleteStrokeCoordinates(newCoordinates);
            return;
        }

        setStrokes(prevLines => {
            const lastStroke = [...prevLines.slice(-1)[0].coordinates];
            lastStroke.push(getCoordinates(e, canvas));
            const newLine = { size: pencilSize, color: selectedColor, coordinates: lastStroke };
            return [...prevLines.slice(0, -1), newLine];
        });
    };

    const endDrawing = (e) => {
        e.preventDefault();
        if (!isSaved) {
            if (modificationMode === "clear-drawing") {
                setStrokes([]);
            } else if (modificationMode === "delete-stroke") {
                setIsSaved(true);
                saveStrokesToServer();
                setDeleteStrokeCoordinates(null);
            } else {
                setIsSaved(true);
                saveStrokesToServer();
            }
        }
    };
    
    const saveBackgroundToServer = (background) => {
        if (!strokes) return;
        axios.post('/api/therapist/drawing-save.php', { background, email })
        .catch(error => {
            console.error('Error:', error);
            logout();
        });
    };

    const saveStrokesToServer = () => {
        if (!strokes) return;
        axios.post('/api/therapist/drawing-save.php', { strokes, email })
        // .then(response => {
            // if (response.data && response.data.strokes && isSaved) {
            //     setStrokes(response.data.strokes);
            // }
        // })
        .catch(error => {
            console.error('Error:', error);
            logout();
        });
    };

    useEffect(() => {
        document.body.classList.add('overflow-hidden');
        return () => { document.body.classList.remove('overflow-hidden'); };
    }, []);

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;

        loadDrawing();
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        drawLines();
        drawBackground();

        canvas.addEventListener('mousedown', startDrawing);
        canvas.addEventListener('mousemove', draw);
        canvas.addEventListener('mouseup', endDrawing);
        canvas.addEventListener('mouseout', endDrawing);

        canvas.addEventListener('touchstart', startDrawing);
        canvas.addEventListener('touchmove', draw);
        canvas.addEventListener('touchend', endDrawing);
        canvas.addEventListener('touchcancel', endDrawing);

        return () => {
            canvas.removeEventListener('mousedown', startDrawing);
            canvas.removeEventListener('mousemove', draw);
            canvas.removeEventListener('mouseup', endDrawing);
            canvas.removeEventListener('mouseout', endDrawing);

            canvas.removeEventListener('touchstart', startDrawing);
            canvas.removeEventListener('touchmove', draw);
            canvas.removeEventListener('touchend', endDrawing);
            canvas.removeEventListener('touchcancel', endDrawing);
        };
    }, [modificationMode, isSaved, strokes, background]);

    const getCoordinates = (e, canvas) => {
        const rect = canvas.getBoundingClientRect();
        let offsetX, offsetY;
        if (e.clientX && e.clientY) {
            offsetX = (e.clientX - rect.left) / (rect.right - rect.left) * canvas.width;
            offsetY = (e.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height;
        } else if (e.touches && e.touches[0]) {
            offsetX = (e.touches[0].clientX - rect.left) / (rect.right - rect.left) * canvas.width;
            offsetY = (e.touches[0].clientY - rect.top) / (rect.bottom - rect.top) * canvas.height;
        }
        return { x: offsetX, y: offsetY };
    };

    //background
    const backgroundSlots = [0, 1, 2];
    const backgroundButtonsHead = backgroundSlots.map((slotId) => {
        return ( <th key={"slotId-" + slotId}>{t("drawing-back-pos" + slotId)}</th> );
    });
    const backgroundButtonsBody = drawingBackList.map((backOption) => {
        const rowCells = backgroundSlots.map((slotId) => {
            const selected = background[slotId] === backOption.id;
            return (
                <td key={ "backOptionId-" + backOption.id + "slotId-" + slotId}>
                    { selected ? (<p className='background-selected'>x</p>) : (
                        <button onClick={() => changeBackgroundClick(slotId, backOption.id) } className='back-button'>-</button>
                    ) }
                </td>
            );
        });
        return (
        <tr key={ "tr-backOptionId-" + backOption.id}>
            <td className='back-image-desc'>{t(backOption['label-key'])}</td>
            {rowCells}
        </tr>);
    });

    const pencilButtons = pencils.map((pencil) => {
        const htmlClass = "pencileButton " + pencil.label + (pencilSize === pencil.pencilSize ? " pencileButtonActive" : "");
        return (
            <button key={pencil.label} className={htmlClass} onClick={() => setPencilSize(pencil.pencilSize)}>
                {t(pencil.label)}
            </button>
        );
    });

    const colorButtons = colors.map((color) => {
        const htmlClass = "colorButton" + (selectedColor === color.value ? " colorButtonActive" : "") + " button-" + color.label;
        return (
            <button key={color.label} className={htmlClass} onClick={() => setSelectedColor(color.value)}>
                <span>{t(color.label)}</span>
            </button>
        );
    });

    const modificationModsButtons = modificationMods.map((modMode) => {
        const modClassName = modMode.label + (modificationMode === modMode.label ? " modifyModActive" : "")
        return (
            <button key={modMode.label} className={modClassName} onClick={() => setModificationMode(modMode.label)}>
                {t("drawing-toolbox-" + modMode.label)}
            </button>
        );
    });

    return (
        <div className="container">
            <div className="main-content">
                <canvas className="drawing-board" ref={canvasRef} width={canvasWidth} height={canvasHeight} />
            </div>
            <div className="toolBox">
                <fieldset className='backgroundButtons'>
                    <legend>{t("drawing-toolbox-background")}</legend>
                    <table className='backgroun-buttons'>
                        <thead>
                            <tr><th></th>{backgroundButtonsHead}</tr>
                        </thead>
                        <tbody>{backgroundButtonsBody}</tbody>
                    </table>
                </fieldset>
                <fieldset className='pencilButtons'>
                    <legend>{t("drawing-toolbox-size")}</legend>
                    {pencilButtons}
                </fieldset>
                <fieldset className='colorButtons'>
                    <legend>{t("drawing-toolbox-color")}</legend>
                    {colorButtons}
                </fieldset>
                <fieldset className='modifyButtons'>
                    <legend>{t("drawing-toolbox-modification")}</legend>
                    {modificationModsButtons}
                </fieldset>
            </div>
        </div>
    );
};

export default DrawingBoard;
