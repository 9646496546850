import React from 'react';
import { useTranslation } from 'react-i18next';

import './datePickerYearPopup.css'; // Correct lower case import

const DatePickerYearPopup = ({ years, handleYearSelect }) => {
    const { t } = useTranslation();

    return (
        <table className="popup year-table">
            <tbody>
                {years.reduce((acc, year) => {
                    const decadeStart = Math.floor(year / 10) * 10;
                    if (!acc.some(group => group.label === decadeStart)) {
                        acc.push({
                            label: decadeStart,
                            years: []
                        });
                    }
                    const currentGroup = acc.find(group => group.label === decadeStart);
                    currentGroup.years.push(year);
                    return acc;
                }, []).map((group) => (
                    <tr key={group.label}>
                        <td className="decade-label">
                            {`${group.label}${t('year-decade-suffix')}`}
                        </td>
                        <td>
                            <table className="nested-year-table">
                                <tbody>
                                    {Array.from({ length: 2 }).map((_, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {Array.from({ length: 5 }).map((_, colIndex) => {
                                                const year = group.label + (rowIndex * 5 + colIndex);
                                                return (
                                                    <td
                                                        key={year}
                                                        className="popup-item"
                                                        onClick={() => handleYearSelect(String(year))}>
                                                        {year}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default DatePickerYearPopup;
