import React, { useState, useEffect } from 'react';
import DayInput from './dayInput';
import MonthInput from './monthInput';
import YearInput from './yearInput';
import './datePicker.css';

const DatePicker = ({ name, value, onChange, onBlur }) => {
    let dayValue = '';
    let monthValue = '';
    let yearValue = '';
    if (value) {
        const parsedDate = new Date(value);
        if (!isNaN(parsedDate)) {
            dayValue = String(parsedDate.getDate()).padStart(2, '0');
            monthValue = String(parsedDate.getMonth() + 1).padStart(2, '0');
            yearValue = String(parsedDate.getFullYear());
        }
    }

    const [day, setDay] = useState(dayValue);
    const [month, setMonth] = useState(monthValue);
    const [year, setYear] = useState(yearValue);

    const selectDay = (d) => {
        console.log(d);
        setDay(d);
        // checkAndCommit();
        if (day !== d) {
            focusNextInput('day');
        }
    }

    const selectMonth = (m) => {
        setMonth(m);
        // checkAndCommit();
        if (month !== m) {
            focusNextInput('month');
        }
    }

    const selectYear = (y) => {
        setYear(y);
        // checkAndCommit();
        if (year !== y) {
            focusNextInput('year');
        }
    }

    useEffect(() => {
        checkAndCommit()
    }, [year, month, day]);

    const checkAndCommit = () => {
        const isoDateString = `${year}-${month}-${day}`;
        if (onChange && day && month && year && !isNaN(new Date(isoDateString).getTime())) {
            const target = {
                name,
                value: `${year}-${month}-${day}`, // ISO format
            };
            onChange({ target });
            onBlur({ target });
        }
    }

    const focusNextInput = (inputType) => {
        let nextInput;

        switch (inputType) {
            case 'day':
                nextInput = document.querySelector('.date-input-month'); // Focus on month
                break;
            case 'month':
                nextInput = document.querySelector('.date-input-year'); // Focus on year
                break;
            case 'year':
                // Get all relevant form elements
                const allInputs = document.querySelectorAll('input, textarea, select, checkbox');

                // Find the next input element after the DatePicker's parent
                let foundDatePicker = false;
                for (let input of allInputs) {
                    if (input.closest('.date-picker-container')) {
                        foundDatePicker = true; // Mark if the input is within the DatePicker
                    } else if (foundDatePicker) {
                        nextInput = input; // The next input after the DatePicker
                        break;
                    }
                }
                break;
            default:
                return;
        }

        // Focus the determined next input
        if (nextInput) {
            nextInput.focus();
        }
    };

    return (
        <div className="date-picker-container left">
            <DayInput day={day} setDay={selectDay} />
            <h1 className='inline'>.</h1>
            <MonthInput month={month} setMonth={selectMonth} />
            <h1 className='inline'>.</h1>
            <YearInput year={year} setYear={selectYear} />
        </div>
    );
};

export default DatePicker;
