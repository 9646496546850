// Scripts
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function NewCustomerForm() {
    const [email, setEmail] = useState('');
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const language_code = i18n.language;

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('/api/account/init.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify({ email, language_code, therapist_registration: true })
            });

            const result = await response.json();

            if (result.success) {
                navigate(`/account?email=${encodeURIComponent(email)}`);
            } else {
                // handle error
                console.error('Submission failed:', result);
            }
        } catch (error) {
            // handle fetch error
            console.error('Fetch error:', error);
        }
    };

    return (
        <div>
            <h3>{t('login-new-customer')}</h3>
            <form onSubmit={handleSubmit}>
                <span>{t('login-email')}: </span>
                <input
                    className='space-right'
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autocomplete='off' // Disable credential manager to save, because no credentials.
                />
                <br></br>
                <button type='submit'>{t('login-add-new-customer')}</button>
            </form>
        </div>
    );
}

export default NewCustomerForm;
