import React, { useState } from 'react';
import DatePickerYearPopup from './datePickerYearPopup';

const YearInput = ({ year, setYear }) => {
    const [value, setValue] = useState(year || ''); // Local state for year
    const [activePicker, setActivePicker] = useState(false);

    const handleYearChange = (e) => {
        const value = e.target.value;
        setValue(value);
        if (value.length === 4) {
            setYear(value); // Update parent state once 4 characters are entered
        }
    };

    const handleYearSelect = (selectedYear) => {
        setValue(selectedYear);
        setYear(selectedYear); // Update parent state on selection from popup
        setActivePicker(false);
    };

    const handleBlur = (e) => {
        if (value.length === 4) {
            setYear(value); // Update parent state on blur if valid
        }

        if (e.relatedTarget) {
            setActivePicker(false);
        }
    };

    const currentYear = new Date().getFullYear();
    const startYear = Math.floor(currentYear / 10) * 10 - 1; // Start of decade
    const years = Array.from({ length: 90 }, (_, i) => startYear - i); // 90 years back

    return (
        <React.Fragment>
            <input
                className="date-input-year generic-input"
                type="text"
                value={value}
                onChange={handleYearChange}
                onFocus={() => setActivePicker(true)}
                onBlur={handleBlur}
                placeholder="YYYY"
            />
            {activePicker && (
                <DatePickerYearPopup
                    years={years}
                    handleYearSelect={handleYearSelect}
                />
            )}
        </React.Fragment>
    );
};

export default YearInput;
