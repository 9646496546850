import React from 'react';
import './processingCircle.css';

const ProcessingCircle = ({ width = 50, height = 50 }) => {
    return (
        <div className="processing-circle" style={{ width, height }}>
            <svg viewBox="0 0 50 50" style={{ width: '100%', height: '100%' }}>
                <circle
                    className="processing-path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                />
            </svg>
        </div>
    );
};

export default ProcessingCircle;
