//Scripts
import { useTranslation } from 'react-i18next';

//Images
import locationImg from './../img/location.jpg';

function Location() {
    const { t } = useTranslation();
      
    return (
        <div>
            <h2>{t('location-header')}</h2>
            <p>{t('header-title')}</p>
            <p>{t('location-street-no')}</p>
            {t('location-zip-city')}
            <br></br>
            <br></br>

            <a className='underline' href='https://www.google.com/search?q=google+maps+from%3A+my+location+to%3A+8152+Glattbrugg+Europa-Strasse+3'>{t('about-us-google-navi')}</a>
            <br></br>
            <br></br>

            <iframe 
                title='google-map'
                className="google-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21592.281072551476!2d8.539075687825807!3d47.430758262805014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xed0b36801952401%3A0xbfe121447bb96905!2sAnatomicus%20Medizinische%20Massage%20GmbH!5e0!3m2!1sen!2sch!4v1727713591618!5m2!1sen!2sch" 
                width="600" 
                height="450" 
                style={ {"border": 0}} 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
            </iframe>
            
            <h2>{t('openinghours-header')}</h2>
            <p>{t('openinghours-content')}</p>

            <h2>{t('impressum-header')}</h2>
            {t('location-street-no')}<br />
            {t('location-zip-city')}<br /><br />
            {t('about-us-phone') + ": "}<a class='underline' href="tel:+41445937337">+41 44 593 73 37</a><br />
            {t('profile-email')}: <a className='underline' href="mailto:info@anatomicus.ch">info@anatomicus.ch</a><br /><br />
            {t('legal-form')}<br />
            {t('commercial-register-prop')} <a className='underline' href="https://zh.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=CHE-303.755.510">{t('commercial-register-value')}</a><br />
            {t('authorized-to-sign')} Albina Schiltknecht
        </div>
    )
}

export default Location;
