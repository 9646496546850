// Libraries
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import LangLink from "../../translations/LangLink.js";
import VoucherList from './voucherList.js'
import voucherSujets from '../../vouchers/voucherSujets.json';
import VoucherAccounting from './voucherAccounting.js';

//CSS
import './voucherManagement.css';

function VoucherManagement({loginUser, logout}) {
    const { t } = useTranslation();
    const [showArchiveList, setShowArchiveList] = useState(null);

    const languageCodes = ['de', 'en', 'ru'];
    const exampleVoucherLinks = voucherSujets.map((sujetType) => {
        if (sujetType.id === 0) return null;
    
        return (
            <div key={sujetType.id}>
                {t('example-' + sujetType.label) + ": "}
                {languageCodes.map((langCode, index) => (
                    <React.Fragment key={langCode}>
                        <LangLink
                            className={'underline'}
                            target='_blank'
                            to={`/api/pdf/voucher.php?language_code=${langCode}&code=example-${sujetType.id}`}
                        >
                            {t(`language-${langCode}`)}
                        </LangLink>
                        {index < languageCodes.length - 1 && ' '}
                    </React.Fragment>
                ))}
            </div>
        );
    });

    return (
        <div>
            <h2>{t('voucher-management')}</h2>
            <h3>{t('generally')}</h3>
            <LangLink className='underline' to='/vouchers'>{t('voucher-request')}</LangLink>
            <br></br><br></br>
            {exampleVoucherLinks}
            <VoucherList 
                loginUser={loginUser}
                logout={logout}
                showArchive={false}>
            </VoucherList>
            <h3>{t('voucher-accounting')}</h3>
            <VoucherAccounting
                loginUser={loginUser}
                logout={logout} >
            </VoucherAccounting>
            <br></br>
            {showArchiveList ? 
                <VoucherList 
                    loginUser={loginUser}
                    logout={logout}
                    showArchive={true}>
                </VoucherList> : 
                <button onClick={() => setShowArchiveList(true)}>
                    {t('voucher-archive-list-show')}
                </button>
            }
        </div>
    );
}

export default VoucherManagement;
