//Scripts
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerSelectElement from './customerSelectElement.js'

//CSS
import './twoRowCustomerFilter.css';

function TwoRowCustomerFilter({ customers, allCustomerFilterAvailable, filterEmail, setFilterEmail, choicePerRow }) {
    const { t } = useTranslation();
    const [localFilter, setLocalFilter] = useState('');

    const firstRowEndIndex = choicePerRow + (allCustomerFilterAvailable ? -1 : 0);
    const secondRowEndIndex = firstRowEndIndex + choicePerRow;

    const filteredCustomers = customers.reduce((acc, customer) => {
        // Apply customerFilter logic
        if (
            (
                localFilter === '' || localFilter === null ||
                customer['firstname'] && customer.firstname.toLowerCase().includes(localFilter) ||
                customer['lastname'] && customer.lastname.toLowerCase().includes(localFilter) ||
                customer.email.includes(localFilter)
            )
        ) {
            acc.push(customer);
        }
        return acc;
    }, [])

    return (
        <div>
            <div className='filter-row'>
                <div className='filter-label'>
                    <span>{t('cl-filter-customer-header')}</span>
                </div>
                {allCustomerFilterAvailable ? (
                    <div 
                        key='customer-all' 
                        onClick={() => setFilterEmail(null)}
                        className={'filter-option filter-option-customer' + (filterEmail === '' || filterEmail === null ? ' filter-option-active' : '')}>
                        <span>{t('cl-filter-customer-option-all')}</span>
                    </div>
                ) : null}
                {filteredCustomers.slice(0, firstRowEndIndex).map((customer) => (
                    <CustomerSelectElement 
                        key={'cse-' + customer.email} 
                        customer={customer} 
                        filterEmail={filterEmail}
                        localFilter={localFilter} 
                        setFilterEmail={setFilterEmail} >
                    </CustomerSelectElement>
                ))}
            </div>
            <div className='filter-row'>
                    <input
                        className='filter-label filter-label-input'
                        onChange={(e) => setLocalFilter(e.target.value.toLowerCase())} 
                        placeholder={t('cl-filter-customer-placeholder')}>
                    </input>
                {filteredCustomers.length > 4 ? 
                    filteredCustomers.slice(firstRowEndIndex, secondRowEndIndex).map((customer) => (
                        <CustomerSelectElement 
                            key={'cse-' + customer.email} 
                            customer={customer} 
                            filterEmail={filterEmail}
                            localFilter={localFilter} 
                            setFilterEmail={setFilterEmail} >
                        </CustomerSelectElement>
                    )) : null}
            </div>
        </div>
    );
}

export default TwoRowCustomerFilter;
