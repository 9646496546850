//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import Login from './login.js';
import EmailRegistration from './emailRegistration.js';
import LangLink from './../translations/LangLink.js'

//CSS
import './accountOverview.css';

//Images
import logoutImg from './../img/logout.png';
import bookingImg from './../img/booking.png';
import profileImg from './../img/profile.png';


function AccountOverview({loginUser, setLoginUser}) {
    const { t } = useTranslation();

    if (!loginUser) {
        return (
            <div className='account-overview'>
                <EmailRegistration />
                <Login setLoginUser={setLoginUser} />
            </div>
        );
    }
    
    return (
        <div className='account-overview'>
            
            {/* <div className='account-overview-item border-right'>
                <LangLink to={`/vouchers`}>
                    <svg
                        className='icon' 
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1"
                        stroke="currentColor"
                        >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                        />
                    </svg>
                    <p className='text'>{t('vouchers')}</p>
                </LangLink>
            </div> */}
            <div className='account-overview-item border-right'>
                <LangLink to={`/account`}>
                    <img className='icon' src={profileImg} alt="profile"></img>
                    <p className='text'>{t('profile-data')}</p>
                </LangLink>
            </div>
            <div className='account-overview-item border-right'>
                <LangLink to={`/bookings`}>
                    <img className='icon' src={bookingImg} alt="bookings"></img>
                    <p className='text'>{t('profile-bookings')}</p>
                </LangLink>
            </div>
            <div className='account-overview-item'>
                <button onClick={() => setLoginUser(null)}>
                    <img className='icon' src={logoutImg} alt="logout"></img>
                    <p className='text'>{t('profile-logout')}</p>
                </button>
            </div>
        </div>
    );
}

export default AccountOverview;
