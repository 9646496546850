//CSS
import './App.css';

//Scripts

import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import TherapyAndDuration from './therapy/therapy-duration';
import Calendar from './calendar/calendar.js';
import { TherapyList } from './therapy/therapyList.js';
import Account from './login/account.js';
import BookView from './booking/bookView';
import LanguageMenu from './translations/languageMenu.js';
import Cookies from 'js-cookie';
import CurrentAndPastBookings from './booking/currentAndPastBookings.js';
import axios from 'axios';
import { stringFormat } from './calendar/calendarLogic';
import AccountOverview from './login/accountOverview.js';
import TherapistOverview from './therapist/therapistOverview.js';
import ClientList from './therapist/clientList.js';
import BookingList from './therapist/bookingList/bookingList.js';
import Absences from './therapist/absences/absences.js';
import DrawingBoard from './therapist/drawingBoard.js'
import OperationPlan from './therapist/operationPlan/operationPlan.js'
import AboutUsMenu from './aboutUs/aboutUsMenu.js'
import Location from './aboutUs/location.js'
import Therapists from './aboutUs/therapists.js'
import { IsProd, IsTest } from './utils/environment.js'
import Journal from './therapist/journal.js'
import { getBrowserLanguage } from './utils/businessUtils.js'
import LangLink from './translations/LangLink.js'
import CashRegister from './therapist/cashRegister/cashRegister.js'
import VoucherManagement from './therapist/voucher/voucherManagement.js'
import Vouchers from './vouchers/vouchers.js';
import ProcessingCircle from './utils/processingCircle.js';

//Images
import logo from './img/logo.png';
import loginImage0 from './img/login-0.png';
import loginImage0Active from './img/login-0-active.png';
import loginImage1 from './img/login-1.png';
import loginImage1Active from './img/login-1-active.png';
import languageImage from './img/language.png';
import languageImageActive from './img/language-active.png';
import therapistImage from './img/therapist.png';
import therapistImageActive from './img/therapistActive.png';
import aboutUs from './img/about-us.png';
import aboutUsActive from './img/about-us-active.png';
import homeImg from './img/home.png'

//Translations
import enTranslation from './translations/en.json';
import deTranslation from './translations/de.json';
import ruTranslation from './translations/ru.json';
import AboutMe from './aboutUs/aboutMe.js';
import Conditions from './aboutUs/conditions.js';

//Translation initialization
const resources = {
    de: { translation: deTranslation },
    en: { translation: enTranslation },
    ru: { translation: ruTranslation },
}
i18next.use(initReactI18next).init({
    resources,
    lng: getBrowserLanguage(resources),
    fallbackLng: 'de',
    interpolation: {
        escapeValue: false,
    },
});

function App() {
    const navigate = useNavigate();
    const [fetchUserStatus, setFetchUserStatus] = useState(null);

    const location = useLocation();
    const [loginUser, setLoginUser] = useState(() => {
        // console.log("read user from cookies");
        const cookieValue = Cookies.get('user');
        return cookieValue ? JSON.parse(cookieValue) : null;
    });

    const searchParams = new URLSearchParams(location.search);
    const atBooking = useMatch('/book');
    const isHome = useMatch('/');
    const loginRequiredList = [
        useMatch('/bookings'), 
        useMatch('/account'), 
        useMatch('/clientList'), 
        useMatch('/bookingList'), 
        useMatch('/drawingBoard'), 
        useMatch('/operationPlan'), 
    ];
    const isLoginRequired = loginRequiredList.some(item => item !== null && item !== undefined);
    const hideHeaderAndFooter = useMatch('/drawingBoard');

    const language = searchParams.get('lang');
    useEffect(() => {
        if (language) {
            i18next.changeLanguage(language);
        }
    }, [language]);

    if (isHome && fetchUserStatus === "redirect") {
        setFetchUserStatus(null);
    }
    
    // console.log("isLoginRequired=" + isLoginRequired + " | isHome=" + isHome + " | fetchUserStatus=" + fetchUserStatus)
    // useEffect(() => {
    //     if (isLoginRequired && !isHome && fetchUserStatus === "redirect") {
    //         handleTopPopup("login");
    //         // navigate('/');
    //     }

    //     const pw = searchParams.get('pw');
    //     const email = searchParams.get('email');
    //     if (pw && email) {
    //         removeCredentialsFromUrl();
    //     }
    // }, [navigate, fetchUserStatus]);

    const [activePopUp, setActivePopUp] = useState(useMatch('/book') && !loginUser ? "login" : null);
    const { t, i18n } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        searchParams.set('lang', language);
        navigate(`${location.pathname}?${searchParams.toString()}`);
    };

    const setPopup = (popupType) => {
        console.log("menu choice: " + (popupType || "none"));
        setActivePopUp(popupType);
    };

    useEffect(() => {
        if (IsProd()) {
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16578861916';
            document.head.appendChild(script);
            script.onload = () => {
                const inlineScript = document.createElement('script');
                inlineScript.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'AW-16578861916');`;
                document.head.appendChild(inlineScript);
            };
        }
    }, []);

    const reloadLoginUser = () => {
        const cookieValue = Cookies.get('user');
        if (cookieValue && cookieValue != undefined && cookieValue != "undefined") {
            const newUser = JSON.parse(cookieValue)
            setLoginUser(newUser);
        } else {
            setLoginUser(null);
        }
    }

    useEffect(() => {
        if (atBooking) {
            const intervalId = setInterval(() => {
                    reloadLoginUser();
                }, 1500);
                reloadLoginUser();
                return () => {
                    clearInterval(intervalId);
            };
        }
    }, []);

    const removeCredentialsFromUrl = () => {
        // Navigate to URL without authentification information
        searchParams.delete('email');
        searchParams.delete('pw');
        const updatedUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(updatedUrl);
    };

    const sendAuthenticationLink = () => {
        const email = searchParams.get('email');
        setFetchUserStatus("loading");
        axios.post('/api/account/sendLoginLink.php', {
            email: email
        })
        .then(response => {
            // console.log('Update successful:', response.data);
            if (response.data.success) {
                setFetchUserStatus("success");
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const logout = async () => {
        setActivePopUp("login");

        // Step 1: Redirect if restricted
        if (isLoginRequired) {
            navigate('/');
        }

        // Step 2: Notify server about logout
        try {
            const response = await axios.post('/api/account/logout.php');
            if (response.data.success) {
                console.log('User logged out successfully.');
            }
        } catch (error) {
            console.error('An error occurred during the logout process:', error);
        }

        // Step 3: Clear client-side cookies
        Cookies.remove('user');
        Cookies.remove('PHPSESSID');

        setLoginUser(null);
    };

    const handleTopPopup = (popupType) => {
        setActivePopUp(activePopUp === popupType ? null : popupType);
    };

    const setLoginUserCacheAndCookies = (user) => {
        if (user == null) {
            logout();
        } else {
            Cookies.set('user', JSON.stringify(user), { sameSite: 'Strict' });
        }

        setFetchUserStatus("done");
        setLoginUser(user);
    }

    const updateLoginUser = (property, value) => {
        loginUser[property] = value;
        setLoginUserCacheAndCookies(loginUser);
    }

    // Ensure authentification where needed
    if (fetchUserStatus === null) {
        const email = searchParams.get('email');
        const pw = searchParams.get('pw');
        const loginDataFromUrl = email !== null && email !== undefined && pw !== null && pw !== undefined;
        if (isLoginRequired && !loginUser && !loginDataFromUrl) {
            // Without login and without authentication-info... => we only can redirect to home.
            setFetchUserStatus("unauthenticated");
            handleTopPopup("login");
            return;
        }

        if (loginDataFromUrl) {
            // Change fetch-user-status to loading. Loading / login fallows right ahead...
            setFetchUserStatus("loading");
    
            // Login at server
            const postData = {
                email: email,
                pw: pw || '',
            };
            const url = `/api/account/login.php`;
            axios.post(url, postData)
            .then(response => {
                if (response.data && response.data["statusLabel"] && response.data.statusLabel === "login-link-needed") {
                    setFetchUserStatus("login-link-needed");
                } else {
                    // Set login user
                    // console.log("user: " + response.data.user);
                    if (response.data.success) {
                        setFetchUserStatus(null);
                        setLoginUserCacheAndCookies(response.data.user);
                    } else {
                        setFetchUserStatus("unauthenticated");
                        handleTopPopup("login");
                    }
                    
                    // Navigate to URL without authentification information
                    removeCredentialsFromUrl();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });

        }
    }

    var authenticationView = null;
    if (fetchUserStatus === "redirect") {
        // This isn't really displayed as we redirect...
        // But it's randered anyway and therefore needed to supress to go into Routes (farther and more randering).
        authenticationView = (
            <h2>Redirect...</h2>
        );
    } else if (fetchUserStatus === "loading") {
        // Data loading / login in progress...
        authenticationView = (
            <div>
                <h2>{t('data-loading')}</h2>
                <ProcessingCircle></ProcessingCircle>
            </div>
        );
    } else if (fetchUserStatus === "login-link-needed"){
        // The login link is outdated. Therefore a new link is to be sent to the client.
        var loginLinkSentInfo = null;
        const email = searchParams.get('email');
        authenticationView = (
            <div className="profile">
                <h2>{t("login-link-outdated-header")}</h2>
                <p>{stringFormat(t("login-link-outdated-content"), email)}</p>
                <button onClick={sendAuthenticationLink}>
                    {stringFormat(t("login-link-send-now"), email)}
                </button>
                <p>{loginLinkSentInfo}</p>
            </div>
        );
    } else if (fetchUserStatus === "unauthenticated" || isLoginRequired && !loginUser) {
        // Login needed first
        authenticationView = (
            <h2>{t('data-unauthenticated')}</h2>
        );
    }

    const therapistButton = loginUser && loginUser["is_therapist"] ? (
        <div className={("menu-content" + (activePopUp === 'therapist' ? " menu-content-active" : " menu-content-passive"))}>
            <button className='menu-content-button' onClick={() => handleTopPopup('therapist')}>
                <img className='menu-icon img-passive' src={therapistImage} alt="therapist"></img>
                <img className='menu-icon img-active' src={therapistImageActive} alt="therapist-active"></img>
                <p>{t('menu-therapist-button')}</p>
            </button>
        </div>
    ) : null;

    const accountButton = loginUser ? (
        <button className='menu-content-button' onClick={() => handleTopPopup('login')}>
            <img className='menu-icon img-passive' src={loginImage1} alt="login-done"></img>
            <img className='menu-icon img-active' src={loginImage1Active} alt="login-done-active"></img>
                <p>{t('menu-account-button')}</p>
        </button>
    ) : (
        <button className='menu-content-button' onClick={() => handleTopPopup('login')}>
            <img className='menu-icon img-passive' src={loginImage0} alt="login"></img>
            <img className='menu-icon img-active' src={loginImage0Active} alt="login-active"></img>
            <p>{t('menu-account-button')}</p>
        </button>
    )

    const header = hideHeaderAndFooter ? null : (
        <header>
            {IsProd() ? null : (
                <p className='environment-info'>{ IsTest() ? "Test-System" : "Development" }</p>
            )}
            <div className="main-header">
                <div className="Logo-Header">
                    <LangLink className="app-logo" to="/">
                        <img src={logo} alt="logo" />
                    </LangLink>
                </div>
                <div className="menu-content">
                    <LangLink className="menu-content-button" to="/">
                        <img className='menu-icon' src={homeImg} alt="home" />
                        <p>{t('menu-home-button')}</p>
                    </LangLink>
                </div>
                <div className={("menu-content" + (activePopUp === 'about-us' ? " menu-content-active" : " menu-content-passive"))}>
                    <button className='menu-content-button' onClick={() => handleTopPopup('about-us')}>
                        <img className='menu-icon img-passive' src={aboutUs} alt="about-us"></img>
                        <img className='menu-icon img-active' src={aboutUsActive} alt="about-us-active"></img>
                        <p>{t('menu-about-us-button')}</p>
                    </button>
                </div>
                <div className={("menu-content" + (activePopUp === 'language' ? " menu-content-active" : " menu-content-passive"))}>
                    <button className='menu-content-button' onClick={() => handleTopPopup('language')}>
                        <img className='menu-icon img-passive' src={languageImage} alt="language"></img>
                        <img className='menu-icon img-active' src={languageImageActive} alt="language-active"></img>
                        <p>{t('menu-your-language-button')}</p>
                    </button>
                </div>
                {therapistButton}
                <div className={("menu-content" + (activePopUp === 'login' ? " menu-content-active" : " menu-content-passive"))}>
                    {accountButton}
                </div>
            </div>
            <div className='menu-extension'>
                {activePopUp === 'about-us' && (
                    <AboutUsMenu />
                )}
                {activePopUp === 'language' && (
                    <LanguageMenu i18next={i18next} changeLanguage={changeLanguage} />
                )}
                {activePopUp === 'login' && (
                    <AccountOverview loginUser={loginUser} setLoginUser={setLoginUserCacheAndCookies} />
                )}
                {activePopUp === 'therapist' && (
                    <TherapistOverview />
                )}
            </div>
        </header>
    );

    return (
        <div className="main">
            {header}
            <main className="App-main">
                {authenticationView ? authenticationView : (
                    <React.Fragment>
                        <TransitionGroup className='transition-group' >
                            <CSSTransition key={location.key} classNames="left" timeout={1000} unmountOnExit>
                                <Routes>
                                    <Route path="/" element={<TherapyList />} />
                                    <Route path="/therapy" element={<TherapyAndDuration />} />
                                    <Route path="/therapists" element={<Therapists />} />
                                    <Route path="/aboutMe" element={<AboutMe />} />
                                    <Route path="/location" element={<Location />} />
                                    <Route path="/conditions" element={<Conditions />} />
                                </Routes>
                            </CSSTransition>
                        </TransitionGroup>
                        <Routes>
                            <Route path="/calendar" element={<Calendar />} />
                            <Route path='/vouchers' element={<Vouchers loginUser={loginUser} logout={logout} updateLoginUser={updateLoginUser} setPopup={setPopup} />} />
                            <Route path="/book" element={<BookView loginUser={loginUser} logout={logout} updateLoginUser={updateLoginUser} />} />
                            <Route path="/account" element={<Account loginUser={loginUser} logout={logout} updateLoginUser={updateLoginUser} minimal={false} />} />
                            <Route path="/bookings" element={<CurrentAndPastBookings loginUser={loginUser} logout={logout} />} />
                            <Route path="/absences" element={<Absences loginUser={loginUser} logout={logout} />} />
                            <Route path="/clientList" element={<ClientList loginUser={loginUser} logout={logout} />} />
                            <Route path="/bookingList" element={<BookingList loginUser={loginUser} logout={logout} />} />
                            <Route path="/drawingBoard" element={<DrawingBoard loginUser={loginUser} logout={logout} />} />
                            <Route path="/operationPlan" element={<OperationPlan loginUser={loginUser} logout={logout} />} />
                            <Route path="/journal" element={<Journal loginUser={loginUser} logout={logout} />} />
                            <Route path="/cashRegister" element={<CashRegister loginUser={loginUser} logout={logout} />} />
                            <Route path="/voucherManagement" element={<VoucherManagement loginUser={loginUser} logout={logout} />} />
                        </Routes>
                    </React.Fragment>
                )}
            </main>
        </div>
    );
}

export default App;
