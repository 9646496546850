//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toDisplayDateTime } from '../utils/dateTimeUtils'
import { getUserDisplay } from '../utils/businessUtils'
import cachTransactionTypes from './cashRegister/cashTransactionTypes.json'
import { getAquiredCustomerDisplay } from './../utils/businessUtils.js'
import paymentTypes from './bookingList/paymentTypes.json'

//CSS
import './accounting.css';

function Accounting({allTransactions, specificAction, specificAccountingPdf }) {
    const { t } = useTranslation();

    const specificSubject = (payment) => {
        if (payment["cash_type_id"]) {
            const cashTransactionType = cachTransactionTypes.find(item => item.id === payment["cash_type_id"]);
            return t(cashTransactionType.translationCode);
        } else if (payment["type_id"]) {
            const paymentType = paymentTypes.find(item => item.id === payment["type_id"]);
            if (paymentType) {
                return t(paymentType.label);
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    const transactionGroups = allTransactions.reduce((groups, transaction) => {
        // Extract the date from the transaction and create a Date object
        const date = new Date(transaction.date);
    
        // Get the month and year as a string (e.g., "January 2023")
        const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    
        // Find an existing group for this month and year
        let group = groups.find(g => g.monthYear === monthYear);
    
        // If the group doesn't exist, create it
        if (!group) {
            group = { monthYear, transactions: [] };
            groups.push(group);
        }
    
        // Add the transaction to the group
        group.transactions.push(transaction);
    
        return groups;
    }, []);

    return transactionGroups.map(group => {
        const firstDate = new Date(group.transactions[0].date);
        return (
            <div className='accounting-container' key={"month-year-" + group.monthYear}>
                <h4>{group.monthYear}</h4>
                <table className='accounting-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('date')}</th>
                            <th>{t('subject')}</th>
                            <th>{t('context-info')}</th>
                            <th>{t('amount')} [CHF]</th>
                            <th>{t('balance')} [CHF]</th>
                        </tr>
                    </thead>
                    <tbody>
                        {group.transactions.map(transaction => 
                            {
                                const userDisplayName = getUserDisplay(transaction);
                                const aquiredUserDisplayName = getAquiredCustomerDisplay(transaction);
                                return (
                                    <tr key={"transaction-id-" + transaction.id}>
                                        <td>{specificAction(transaction)}</td>
                                        <td>{toDisplayDateTime(new Date(transaction.date))}</td>
                                        <td>{specificSubject(transaction)}</td>
                                        <td>
                                            <ul className='accounting-context'>
                                                {transaction['voucher_id'] ? 
                                                    <li>{t('voucher-no') + " V" + transaction['voucher_id'].toString().padStart(6, '0')}</li> : 
                                                    null
                                                }
                                                {userDisplayName ? (
                                                    <li>
                                                        {transaction["booking_id"] ? t('cash-from-booking-with') : t('payment-by')}: {userDisplayName}
                                                    </li>
                                                ) : null}
                                                {aquiredUserDisplayName ? (
                                                    <li>
                                                        {t('cash-for-acquisition')}: {aquiredUserDisplayName}
                                                    </li>
                                                ) : null}
                                                {transaction.transaction_text ? (
                                                    <li>
                                                        {t('payment-transaction-text')}: {transaction.transaction_text}
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </td>
                                        <td className='right'>{transaction.amount_chf > 0 ? '+' : ''}{transaction.amount_chf}</td>
                                        <td className='right'>{transaction.balance}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <a className='link' target='blank' href={'/api/pdf/' + specificAccountingPdf + '.php?year=' + firstDate.getFullYear() + "&month=" + (firstDate.getMonth() + 1)}>
                    {t('bookkeeping')} {group.monthYear} ({t('openPdf')})
                </a>
            </div>)
        });
}

export default Accounting;
