//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import FilterHeighlitedValue from '../utils/filterHeighlitedValue.js'
import { filterClients, customerSorting } from '../utils/businessUtils.js'
import ProcessingCircle from './../utils/processingCircle.js';

//CSS
import './bookingByTherapist.css';

function BookingByTherapist({ logout, bookAppointment, skipNotification, setSkipNotification, bookingInFuture }) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [customerList, setCustomerList] = useState(null);
    const [filter, setFilter] = useState("");
    const [sortOrder, setSortOrder] = useState("closest-bookings");

    const onSortorderChange =(e) => {
        setSortOrder(e.target.value);
    }

    const onFilterChange = (e) => {
        setFilter(e.target.value);
    }

    const switchNotification = () => {
        setSkipNotification((prevState) => { return !prevState; })
    }

    const loadClientList = async () => {
        try {
            const bookingInfoUrl = `/api/therapist/clientList.php`;
            const response = await axios.get(bookingInfoUrl);
            setCustomerList(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;

        loadClientList();
    }, []);

    const dateNow = new Date();
    const customerBookingButtons = customerList == null ? 
            <div>
                <h2>{t('data-loading')}</h2>
                <ProcessingCircle></ProcessingCircle>
            </div> : filterClients(customerList, filter)
        .sort((c1, c2) => { return customerSorting(c1, c2, sortOrder, dateNow); })
        .map((customer) => {
        let displayText = null;
        if (customer["firstname"] && customer["lastname"]) {
            displayText = customer["lastname"] + " " + customer["firstname"];
        }
        return (
            <button key={"customer-id-" + customer.id} className='customer-booking-button' onClick={() => bookAppointment(customer.email) }>
                {<FilterHeighlitedValue value={displayText} filter={filter}></FilterHeighlitedValue>}
                <br></br>
                <span className='customer-email'>
                    {<FilterHeighlitedValue value={customer["email"]} filter={filter}></FilterHeighlitedValue>}
                    {/* {customer["closest_booking_date"] ? (
                        <React.Fragment>
                            <br></br>
                            {t('profile-closest_booking_date')}: <TimeDifferenceToNow date={customer["closest_booking_date"]} pastOrFutureIndication={true}></TimeDifferenceToNow>
                        </React.Fragment>
                    ) : null} */}
                </span>
            </button>
        )
    });

    return (
        <div>
            <h2>{t('booking-by-therapist')}</h2>
            <ol>
                <li>{t('booking-by-therapist-info')}</li>
                <li>
                    <label htmlFor='booking-by-therapist-sorting'>{t('booking-by-therapist-sorting')}</label> <select 
                        onChange={onSortorderChange}
                        name='booking-by-therapist-sorting'>
                        <option value={'closest-bookings'}>{t('booking-by-therapist-sorting-closest-bookings')}</option>
                        <option value={'newest-registration'}>{t('booking-by-therapist-newest-registration')}</option>
                    </select>
                </li>
                <li>{t('booking-by-therapist-filter')} <input onChange={onFilterChange} name='filter'></input></li>
                {bookingInFuture ? 
                    (
                        <li>
                            <label htmlFor='notify-checkbox'>
                                {t('booking-by-therapist-notify-text')}
                            </label> <input id='notify-checkbox' type='checkbox' checked={!skipNotification} onChange={switchNotification}></input>
                            { " " + t(skipNotification ? 'booking-by-therapist-notify-no' : 'booking-by-therapist-notify-yes') }
                        </li>
                    ) : (
                        <li>{t('booking-by-therapist-in-past')}</li>
                    )}
            </ol>
            <br></br>
            <p>{t('booking-by-therapist-now')}</p>
            {customerBookingButtons}
        </div>
    );
}

export default BookingByTherapist;
