// Libraries
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Accounting from './../accounting.js'
import ProcessingCircle from './../../utils/processingCircle.js';

//CSS
import './voucherAccounting.css';

function VoucherAccounting({loginUser, logout}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [voucherTransactions, setVoucherTransactions] = useState(null);

    const loadVoucherTransactions = async () => {
        try {
            const voucherTransactionsUrl = `/api/voucher.php?action=accounting`;
            const response = await axios.get(voucherTransactionsUrl);
            if (response.data.Status === "success") {
                setVoucherTransactions(response.data.voucherTransactions);
            }
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    };

    const openBookingInvoice = (bookingId) => {
        const url = "/api/pdf/booking-invoice.php?bookingId=" + bookingId;
        window.open(url, '_blank');
    };

    const openVoucherInvoice = (voucherId) => {
        const url = "/api/pdf/voucher-invoice.php?withPayments=ture&voucherId=" + voucherId;
        window.open(url, '_blank');
    };

    const specificAction = (transaction) => {
        const label = transaction["type_id"] == 5 || transaction["type_id"] == 7 ? "invoice" : "invoice-receipt";
        if (transaction["booking_id"]) {
            return <button onClick={e => openBookingInvoice(transaction["booking_id"])}>
                {t(label)}
            </button>
        } else {
            return <button onClick={e => openVoucherInvoice(transaction["voucher_id"])}>
                {t(label)}
            </button>
        }
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;
            loadVoucherTransactions();
    }, []);

    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (voucherTransactions == null) {
        return <div>
            <h2>{t('data-loading')}</h2>
            <ProcessingCircle></ProcessingCircle>
        </div>
    }

    if (voucherTransactions.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    return (
        <Accounting
            allTransactions={voucherTransactions}
            specificAction={specificAction}
            specificAccountingPdf="voucherAccounting">
        </Accounting>
    )
}

export default VoucherAccounting;
