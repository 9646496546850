import React, { useState } from 'react';
import { monthDisplay } from './../utils/dateTimeUtils';
import { useTranslation } from 'react-i18next';

const MonthInput = ({ month, setMonth }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(month || ''); // Local state for month
    const [activePicker, setActivePicker] = useState(false);

    const handleMonthChange = (e) => {
        const value = e.target.value;
        setValue(value);
        if (value.length === 2) {
            setMonth(value); // Update parent state once two characters are entered
        }
    };

    const handleMonthSelect = (selectedMonth) => {
        setValue(selectedMonth);
        setMonth(selectedMonth); // Update parent state on selection from popup
        setActivePicker(false);
    };

    const handleBlur = (e) => {
        if (value.length === 2) {
            setMonth(value); // Update parent state on blur if valid
        }

        if (e.relatedTarget) {
            setActivePicker(false);
        }
    };

    const months = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));

    return (
        <React.Fragment>
            <input
                className="date-input-month generic-input"
                type="text"
                value={value}
                onChange={handleMonthChange}
                onFocus={() => setActivePicker(true)}
                onBlur={handleBlur}
                placeholder="MM"
            />
            {activePicker && (
                <div className="popup month-popup">
                    {months.map((m, index) => (
                        <div key={m} className="popup-item" onClick={() => handleMonthSelect(m)}>
                            {monthDisplay(t, index)}
                        </div>
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};

export default MonthInput;
