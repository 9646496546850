//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CashCheck from './CashCheck/cashCheck'
import CashTransactionConsole from './cashTransactionConsole.js'
import Accounting from './../accounting.js'
import cachTransactionTypes from './cashTransactionTypes.json'
import ProcessingCircle from './../../utils/processingCircle.js';

//CSS
import './cashRegister.css';

function CashRegister({loginUser, logout}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [cashTransactions, setCashTransactions] = useState(null);

    const loadCashTransactions = async () => {
        try {
            const cashTransactionsUrl = `/api/therapist/cash-transactions.php?action=load`;
            const response = await axios.get(cashTransactionsUrl);
            setCashTransactions(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    const paymentRemove = async (e, paymentId) => {
        e.preventDefault();
        axios.post('/api/therapist/payment-remove.php', {paymentId})
        .then(() => {
            loadCashTransactions();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const openInvoice = (bookingId) => {
        const url = "/api/pdf/booking-invoice.php?bookingId=" + bookingId;
        window.open(url, '_blank');
    };

    const specificAction = (transaction) => {
        const cashTransactionType = cachTransactionTypes.find(item => item.id === transaction["cash_type_id"]);
        if (cashTransactionType.foreignDetermination) {
            return <button onClick={e => openInvoice(transaction["booking_id"])}>
                {t("invoice-receipt")}
            </button>
        } else {
            <button onClick={(e) => paymentRemove(e, transaction.id)}>{t('delete')}</button>
        }
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;
            loadCashTransactions();
    }, []);

    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (cashTransactions == null) {
        return <div>
            <h2>{t('data-loading')}</h2>
            <ProcessingCircle></ProcessingCircle>
        </div>
    }

    if (cashTransactions.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    return (
        <div>
            <CashTransactionConsole reloadTransactions={loadCashTransactions}></CashTransactionConsole>
            <h2>{t('cash-transaction-list')}</h2>
            <Accounting
                allTransactions={cashTransactions}
                specificAction={specificAction}
                specificAccountingPdf="cashAccounting">
            </Accounting>
            <CashCheck transactionBalance={cashTransactions[cashTransactions.length - 1].balance}></CashCheck>
        </div>
    );
}

export default CashRegister;
