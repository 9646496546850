import React, { useState, useRef } from 'react';

const DayInput = ({ day, setDay }) => {
    const [value, setValue] = useState(day || ''); // Local state for day
    const [activePicker, setActivePicker] = useState(false);

    const handleDayChange = (e) => {
        const value = e.target.value;
        setValue(value);
        if (value.length === 2) {
            setDay(value); // Update parent state once two characters are entered
        }
    };

    const handleBlur = (e) => {
        if (value.length === 2) {
            setDay(value); // Update parent state on blur if valid
        }

        if (e.relatedTarget) {
            setActivePicker(false);
        }
    };

    const handleDaySelect = (selectedDay) => {
        setValue(selectedDay);
        setDay(selectedDay); // Update parent state on selection from popup
        setActivePicker(false);
    };

    const days = Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0'));

    return (
        <React.Fragment>
            <input
                className="date-input-day generic-input"
                type="text"
                value={value}
                onChange={handleDayChange}
                onFocus={() => setActivePicker(true)}
                onBlur={handleBlur}
                placeholder="DD"
            />
            {activePicker && (
                <div className="popup day-popup">
                    {days.map((d) => (
                        <div key={d} className="popup-item" onClick={() => handleDaySelect(d)}>
                            {d}
                        </div>
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};

export default DayInput;
